export const HeadProtectionItems = [
    {
        name: 'bullard ltx series',
        image_url: '/images/Head Protection/bullard ltx series.png'
    },
    {
        name: 'bumpcap',
        image_url: '/images/Head Protection/bumpcap.png'
    },
    {
        name: 'chin strap',
        image_url: '/images/Head Protection/chin strap.png'
    },
    {
        name: 'clip clap',
        image_url: '/images/Head Protection/clip clap.png'
    },
    {
        name: 'full brim helmet',
        image_url: '/images/Head Protection/full brim helmet.png'
    },
    {
        name: 'helmet with ratchet suspension',
        image_url: '/images/Head Protection/helmet with ratchet suspension.png'
    },
    {
        name: 'Suspension',
        image_url: '/images/Head Protection/Suspension.png'
    },
    {
        name: 'v guard helmet',
        image_url: '/images/Head Protection/v guard helmet.png'
    },
    {
        name: 'vented safety helmet',
        image_url: '/images/Head Protection/vented sefety helmet.png'
    },
    {
        name: 'welding helmet',
        image_url: '/images/Head Protection/welding helmet.png'
    }
];

export const HandProtectionItems = [
    {
        name: '1001W-RD',
        image_url: '/images/Hand Protection/1001W-RD.png'
    },
    {
        name: '1006-YW',
        image_url: '/images/Hand Protection/1006-YW.png'
    },
    {
        name: '1007-RD-1026',
        image_url: '/images/Hand Protection/1007-RD-1026.png'
    },
    {
        name: '1009-YW-1034',
        image_url: '/images/Hand Protection/1009-YW-1034.png'
    },
    {
        name: 'Blue KNDDA',
        image_url: '/images/Hand Protection/Blue KNDDA.png'
    },
    {
        name: 'Gorilla flex chem I',
        image_url: '/images/Hand Protection/Gorilla flex chem I.png'
    },
    {
        name: 'gorilla Flex Cut 4D',
        image_url: '/images/Hand Protection/gorilla Flex Cut 4D.png'
    },
    {
        name: 'HDPE gloves',
        image_url: '/images/Hand Protection/HDPE gloves.png'
    },
    {
        name: 'PP sleeves',
        image_url: '/images/Hand Protection/PP sleeves.png'
    }
    
];

export const EarProtectionItems = [
    {
        name: '3M 340-4002',
        image_url: '/images/Ear Protection/3M 340-4002.png'
    },
    {
        name: '3M 340-4004',
        image_url: '/images/Ear Protection/3M 340-4004.png'
    },
    {
        name: '3M 391-0000',
        image_url: '/images/Ear Protection/3M 391-0000.png'
    },
    {
        name: '3M 391-1004',
        image_url: '/images/Ear Protection/3M 391-1004.png'
    },
    {
        name: '3M 1100',
        image_url: '/images/Ear Protection/3M 1100.png'
    },
    {
        name: '3M H9A',
        image_url: '/images/Ear Protection/3M H9A.png'
    },
    {
        name: '3M H9P3E',
        image_url: '/images/Ear Protection/3M H9P3E.png'
    },
    {
        name: '3M H10A',
        image_url: '/images/Ear Protection/3M H10A.png'
    },
    {
        name: '3M110',
        image_url: '/images/Ear Protection/3M110.png'
    },
    {
        name: 'Cap Solo',
        image_url: '/images/Ear Protection/Cap Solo.png'
    },
    {
        name: 'dispenser',
        image_url: '/images/Ear Protection/dispenser.png'
    },
    {
        name: 'refill',
        image_url: '/images/Ear Protection/refill.png'
    },
    {
        name: 'Solo Light',
        image_url: '/images/Ear Protection/Solo Light.png'
    },
    {
        name: 'Solo Xtreme',
        image_url: '/images/Ear Protection/Solo Xtreme.png'
    },
    {
        name: 'sync cord',
        image_url: '/images/Ear Protection/sync cord.png'
    },
    {
        name: 'sync',
        image_url: '/images/Ear Protection/sync.png'
    },
    {
        name: 'ultra ear',
        image_url: '/images/Ear Protection/ultra ear.png'
    },
    {
        name: 'Ultra Solo',
        image_url: '/images/Ear Protection/Ultra Solo.png'
    }
];

export const EyeProtectionItems = [
    {
        name: '3M 2890SA',
        image_url: '/images/Eye Protection/3M 2890SA.png'
    },
    {
        name: 'Active - Premium Range',
        image_url: '/images/Eye Protection/Active - Premium Range.png'
    },
    {
        name: 'balaclava, face hood',
        image_url: '/images/Eye Protection/balaclava, face hood.png'
    },
    {
        name: 'cotton hood',
        image_url: '/images/Eye Protection/cotton hood.png'
    },
    {
        name: 'Edge - Basic Plus',
        image_url: '/images/Eye Protection/Edge - Basic Plus.png'
    },
    {
        name: 'Elite - Premium Range',
        image_url: '/images/Eye Protection/Elite - Premium Range.png'
    },
    {
        name: 'Fargo - Basic Plus Range',
        image_url: '/images/Eye Protection/Fargo - Basic Plus Range.png'
    },
    {
        name: 'Flash - Premium Plus Range',
        image_url: '/images/Eye Protection/Flash - Premium Plus Range.png'
    },
    {
        name: 'Hawk - Basic Plus Range',
        image_url: '/images/Eye Protection/Hawk - Basic Plus Range.png'
    },
    {
        name: 'Maxima - Premium Plus Range',
        image_url: '/images/Eye Protection/Maxima - Premium Plus Range.png'
    },
    {
        name: 'Metallic - Premium Range',
        image_url: '/images/Eye Protection/Metallic - Premium Range.png'
    },
    {
        name: 'pc face shield',
        image_url: '/images/Eye Protection/pc face shield.png'
    },
    {
        name: 'RC ultra goggler clear',
        image_url: '/images/Eye Protection/RC ultra goggler clear.png'
    },
    {
        name: 'Ryder - Basic Plus Range',
        image_url: '/images/Eye Protection/Ryder - Basic Plus Range.png'
    },
    {
        name: 'Sporty - Premium Range',
        image_url: '/images/Eye Protection/Sporty - Premium Range.png'
    },
    {
        name: 'Super-Fit - Premium Range',
        image_url: '/images/Eye Protection/Super-Fit - Premium Range.png'
    },
    {
        name: 'tactiacal spoggle',
        image_url: '/images/Eye Protection/tactiacal spoggle.png'
    },
    {
        name: 'ultrasonic goggle',
        image_url: '/images/Eye Protection/ultrasonic goggle.png'
    },
    {
        name: 'virtua ap series',
        image_url: '/images/Eye Protection/virtua ap series.png'
    },
    {
        name: 'virtua lente cinza',
        image_url: '/images/Eye Protection/virtua lente cinza.png'
    },
    {
        name: 'vision clear',
        image_url: '/images/Eye Protection/vision clear.png'
    },
    {
        name: 'vision grey visor combo',
        image_url: '/images/Eye Protection/vision grey visor combo.png'
    },
    {
        name: 'vision grey',
        image_url: '/images/Eye Protection/vision grey.png'
    },
    {
        name: 'Vista - Premium Range',
        image_url: '/images/Eye Protection/Vista - Premium Range.png'
    }    
];

export const FirstAidKitItems = [
    {
        name: 'EW-919',
        image_url: '/images/First Aid Kit/EW-919.png'
    },
    {
        name: 'EW-2990',
        image_url: '/images/First Aid Kit/EW-2990.png'
    },
    {
        name: 'FA-343',
        image_url: '/images/First Aid Kit/FA-343.png'
    },
    {
        name: 'FA-348',
        image_url: '/images/First Aid Kit/FA-348.png'
    },
    {
        name: 'FA-2030',
        image_url: '/images/First Aid Kit/FA-2030.png'
    },
    {
        name: 'FA-2717',
        image_url: '/images/First Aid Kit/FA-2717.png'
    },
    {
        name: 'FA-F30658',
        image_url: '/images/First Aid Kit/FA-F30658.png'
    },
    {
        name: 'FAW5',
        image_url: '/images/First Aid Kit/FAW5.png'
    },
    {
        name: 'Matusa P100',
        image_url: '/images/First Aid Kit/Matusa P100.png'
    },
    {
        name: 'P150',
        image_url: '/images/First Aid Kit/P150.png'
    }
];

export const ProtectiveClothingItems = [
    {
        name: 'bulldozer fireman',
        image_url: '/images/Protective clothing/bulldozer fireman.png'
    },
    {
        name: 'Proguard type 5-6',
        image_url: '/images/Protective clothing/Proguard type 5-6.png'
    },
    {
        name: 'Safeguard C',
        image_url: '/images/Protective clothing/Safeguard C.png'
    },
    {
        name: 'safety jacket',
        image_url: '/images/Protective clothing/safety_jacket.jpg'
    },
    {
        name: 'spartan 1',
        image_url: '/images/Protective clothing/spartan 1.png'
    },
    {
        name: 'spartan 2',
        image_url: '/images/Protective clothing/spartan 2.png'
    },
    {
        name: 'torch apron',
        image_url: '/images/Protective clothing/torch apron.png'
    },
    {
        name: 'torch cover',
        image_url: '/images/Protective clothing/torch cover.png'
    },
    {
        name: 'torch jacket',
        image_url: '/images/Protective clothing/torch jacket.png'
    },
    {
        name: 'Torch sleeves',
        image_url: '/images/Protective clothing/Torch sleeves.png'
    }
    
];

export const CorporateUniformsItems = [
    {
        name: 'cricket set',
        image_url: '/images/Corporate uniforms/cricket set.jpg',
        desc1:'Available on various colours',
        desc2:'High quality material'        
    },
    {   
        name: 'vecchia',
        image_url: '/images/Corporate uniforms/vecchia.jpg',
        desc1:'Cotton , polymer & dryncool available',
        desc2:'Available on various colours'        
    },
    {
        name: 'inbicta',
        image_url: '/images/Corporate uniforms/inbicta.jpg',
        desc1:'Trendy style',
        desc2:'Special design on requirement'
    },
    {
        name: 'micro jacket',
        image_url: '/images/Corporate uniforms/micro jacket.jpg',
        desc1:'100 percent heavy polyster',
        desc2:'Zipper pockets and snap button opening'
    },
    {
        name: 'reporter jacket',
        image_url: '/images/Corporate uniforms/reporter jacket.jpg',
        desc1:'100 percent cotton',
        desc2:'Various pockets for multiple use'
    },
    {
        name: 'brockey jacket',
        image_url: '/images/Corporate uniforms/brockey jacket.jpg',
        desc1:'Available in different sizes and colours',
        desc2:'Special design on demand'
    },
    {
        name: 'Beland',
        image_url: '/images/Corporate uniforms/Beland.jpg',
        desc1:'100 percent cotton',
        desc2:'Customized as per requirement'
    },
    {
        name: 'milton',
        image_url: '/images/Corporate uniforms/milton.jpg',
        desc1:'Customization available',
        desc2:'Comfy clothes'
    },
    {
        name: 'polymesh track set',
        image_url: '/images/Corporate uniforms/polymesh track set.jpg',
        desc1:'Comfy attire',
        desc2:'Cotton jersey'
    },
    {
        name: 'wahi black',
        image_url: '/images/Corporate uniforms/wahi black.jpg',
        desc1:'Colour-black',
        desc2:'Customization available'
    },
    {
        name: 'wahi white',
        image_url: '/images/Corporate uniforms/wahi white.jpg',
        desc1:'Colour-white',
        desc2:'Customization available'
    },
    {
        name: 'wroclaw and bukini',
        image_url: '/images/Corporate uniforms/wroclaw and bukini.jpg',
        desc1:'Custom made',
        desc2:'Available on various prints and colours'
    },
    {
        name: 'formal shirts and trousers',
        image_url: '/images/Corporate uniforms/formal shirts and trousers.jpg',
        desc1:'Available in various colours and fabric',
        desc2:'Trousers come in PV,PC and cotton'
    },
    {
        name: 'hilton cargo pant',
        image_url: '/images/Corporate uniforms/hilton cargo pant.jpg',
        desc1:'100 percent cotton',
        desc2:'Suitable for watchman’s uniforms'
    },
    {
        name: 'koram',
        image_url: '/images/Corporate uniforms/koram.jpg',
        desc1:'Polycot multipannel shirt',
        desc2:'Smart uniforms'
    },
    {
        name: 'men solid shirt',
        image_url: '/images/Corporate uniforms/men solid shirt.jpg',
        desc1:'Everyday wear',
        desc2:'Easily washable'
    },
    {
        name: 'oxforfd ladies shirt',
        image_url: '/images/Corporate uniforms/oxforfd ladies shirt.jpg',
        desc1:'Formal modern fit',
        desc2:'Customizable in various colours'
    },
    {
        name: '100 percent cotton polo',
        image_url: '/images/Corporate uniforms/100 percent cotton polo.jpg',
        desc1:'Trendy button design',
        desc2:'100 percent cotton'
    },
    {
        name: '100 percent cotton reglan polo',
        image_url: '/images/Corporate uniforms/100 percent cotton reglan polo.jpg',
        desc1:'Trendy button design',
        desc2:'100 percent cotton'
    },
    {
        name: 'alldayfresh polo shirt',
        image_url: '/images/Corporate uniforms/alldayfresh polo shirt.jpg',
        desc1:'UV protection',
        desc2:'Available in various colours'
    },
    {
        name: 'anti microbial polo shirt',
        image_url: '/images/Corporate uniforms/anti microbial polo shirt.jpg',
        desc1:'No-fuss performance',
        desc2:'Creaseless'
    },
    {
        name: 'carrabian polos',
        image_url: '/images/Corporate uniforms/carrabian polos.jpg',
        desc1:'Trendy buttons',
        desc2:'Dry and confortable'
    },
    {
        name: 'corporate polos',
        image_url: '/images/Corporate uniforms/corporate polos.jpg',
        desc1:'Rewashable',
        desc2:'UV protection'
    },
    {
        name: 'dryncool basic polo shirts',
        image_url: '/images/Corporate uniforms/dryncool basic polo shirts.jpg',
        desc1:'Advanced moisture management',
        desc2:'Keeps skin cooler and drier'
    },
    {
        name: 'dryncool with uv protection',
        image_url: '/images/Corporate uniforms/dryncool with uv protection.jpg',
        desc1:'UV protection',
        desc2:'Available in various colours'
    },
    {
        name: 'golf polo shirts',
        image_url: '/images/Corporate uniforms/golf polo shirts.jpg',
        desc1:'Pre dyed yarn',
        desc2:'Freedom sleeves'
    },
    {
        name: 'the grand collection',
        image_url: '/images/Corporate uniforms/the grand collection.jpg',
        desc1:'Special fabric',
        desc2:'Quick evaporation'
    }
    
];





       



